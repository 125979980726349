class GitChartImg {
  constructor(data, callback, options,vue) {
    this.images = [];
    this.data = data;
    this.scale = 1;
    this.quality = 1;
	this.vue = vue;
    this.color = ['#d80000', '#1dcb32', '#1d22d9'];
    this.option = {
      grid: {
        left: '25',
        top: '20',
        right: '10',
        bottom: '25',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'T(s)',
        nameLocation: 'center',
        nameGap: '30',
        nameTextStyle: {
          fontWeight: 'bold',
          fontSize: 14
        },
        axisLabel: {
          formatter: function (value) {
            return Number(value).toFixed(1);
          }
        }
      },
      yAxis: {
        type: 'value',
        min: -10,
        max: 10,
        boundaryGap: [0, '100%'],
        name: 'V(cm/s)',
        nameLocation: 'center',
        nameGap: '30',
        nameTextStyle: {
          fontWeight: 'bold',
          fontSize: 14
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ccc'
          }
        },
        axisLabel: {
          formatter: function (value) {
            let value1 = Number(Number(value).toFixed(1));
            let value2 = Number(Number(value).toFixed(2));
            let value3 = Number(Number(value).toFixed(3));
            let value4 = Number(Number(value).toFixed(4));
            return value1 ? value1 : (value2 ? value2 : (value3 ? value3 : (value4 ? value4 : 0)));
          }
        }
      },
      animation: false,
      series: [
        {
          type: 'line',
          smooth: 0.2,
          symbol: 'none',
          // sampling: 'average', //过滤点
          animation: false,
          lineStyle: {width:1},
          itemStyle: {color: ''},
        }
      ]
    };
    if (typeof callback === 'function') {
      this.callback = callback;
    }
    if (typeof options === "object") {
      this.merge = options.merge;
      this.width = options.width;
      this.height = options.height;
    } else {
      this.width = 800;
      this.height = 200;
    }
    if (this.merge) {
      this.createCanvas();
    }
    this.createECharts();
  }

  //创建一个合成三张图片的canvas
  createCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height * 3;
    this.ctx = canvas.getContext('2d');
    this.canvas = canvas;
  }

  //创建三个容器储存echarts
  async createECharts() {
    const containerX = document.createElement('div');
    const containerY = document.createElement('div');
    const containerZ = document.createElement('div');
    this.container = [];

    await [containerX, containerY, containerZ].map(item => {
      item.style.width = this.width + 'px';
      item.style.height = this.height + 'px';
      this.container.push(this.vue.echarts.init(item));
    });

    await this.drawChart();
  }

  //绘制echarts图
  async drawChart() {
    this.images.length = 0;
    await this.container.map((item, index) => {
      let max = this.data.maxData * 1.3;
      max = max === 0 ? 0.2 : max;
      this.option.xAxis.data = this.data.date;
      this.option.series[0].data = this.data.series[index];
      this.option.series[0].itemStyle.color = this.color[index];

      if (this.data.fileType === 'bin') {
        this.option.yAxis.min = -max;
        this.option.xAxis.name = 'T(s)';
        this.option.series[0].type = 'line';
      } else {
        this.option.yAxis.min = 0;
        this.option.xAxis.name = '';
        this.option.series[0].type = 'bar';
      }
      this.option.yAxis.max = max;

      if (this.option && typeof this.option === "object") {
        item.clear();
        item.setOption(this.option, true);
        this.images.push(item.getDataURL());
      }
    });

    await this.drawBase64(this.images);
  }

  //把x,y,z三个方向的折线图都放入一个canvas合成一张图
  drawBase64(images) {
    if (images.length === 3) {
      const _this = this;
      if (!this.merge) {
        _this.callback(images)
      } else {
        images.map((item, index) => {
          const img = new Image;
          img.src = item;
          img.onload = function () {
            _this.ctx.drawImage(img, 0, index * _this.height, img.width, img.height);
            if (index === 2) {
              _this.CompressImages(_this.canvas.toDataURL('images/png'));
            }
          }
        });
      }
    }
  }

  //压缩图片大小
  CompressImages(base64) {
    const img = new Image();
    const _this = this;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    img.src = base64;
    img.onload = function () {
      canvas.width = img.width / _this.scale;
      canvas.height = img.height / _this.scale;
      canvas.style.background = '#fff';
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let base64 = canvas.toDataURL('image/jpeg', _this.quality);
      base64 = base64.replace(/data:image\/jpeg/, 'data:image/png');
      _this.callback(base64);
    }
  }
}

export default GitChartImg;
